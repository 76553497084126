import Quill, { Delta } from 'quill';

export function prepareForEditor(delta: Delta): any {
  if (!delta?.ops?.length) return delta;

  return delta.ops.map((op) => {
    const attributes = op?.attributes as any;

    if (!attributes?.userId) return op;

    const opWithMention = {
      insert: {
        mention: {
          index: '0',
          denotationChar: '',
          id: attributes?.userId,
          value: op.insert,
        },
      },
    };

    return opWithMention;
  });
}

export function prepareForServer(delta: Delta): any {
  if (!delta?.ops?.length) return delta;

  return JSON.stringify(
    delta.ops.map((op) => {
      const insertProp = op?.insert as any;

      if (!insertProp?.mention) return op;

      const updatedOp: any = {
        insert: insertProp?.mention?.value,
        attributes: {
          color: '#2962FF',
          background: '#E1F5FE',
          userId: insertProp?.mention?.id,
        },
      };

      return updatedOp;
    })
  );
}

export function getAllMentionedUserIds(delta: Delta) {
  if (!delta?.ops?.length) return [];

  return delta.ops
    .filter((op) => op?.attributes?.['userId'])
    .map((op) => op.attributes['userId']) as string[];
}

export const emptyDelta = {
  ops: [{ insert: '' }],
};
